import { autorun } from "mobx";
import { rootStore } from "../../../store";
import { TILE_SIZE } from "../config";
import { GameScene } from "../scene/world";

interface UISelectConstructor {
    scene: GameScene
    layer: Phaser.Tilemaps.TilemapLayer
}

/** handle mouse over highlight and tile selection */
export class UISelect extends Phaser.GameObjects.Graphics {
    private mapStore = rootStore.mapStore


    constructor({ scene, layer }: UISelectConstructor) {
        super(scene)

        // this.load.spritesheet('mummy', 'assets/animations/mummy37x45.png', { frameWidth: 37, frameHeight: 45 });
        const animation = scene.anims.create({
            key: 'uiselect-animation',
            frames: scene.anims.generateFrameNumbers('uiselect', { start: 0, end: 1 }),
            frameRate: 4
        });
        const sprite = scene.add.sprite(0, 0, 'uiselect').setScale(1);
        sprite.visible = false
        sprite.depth = 1000

        sprite.play({ key: 'uiselect-animation', repeat: -1 });

        this.scene.add.existing(this);

        // run when selected tile changes
        autorun(() => {
            if (this.mapStore.selectedTile) {

                const x = this.mapStore.selectedTile.x
                const y = this.mapStore.selectedTile.y

                sprite.x = x * TILE_SIZE + TILE_SIZE / 2
                sprite.y = y * TILE_SIZE + TILE_SIZE / 2
                sprite.visible = true
                // this.scene can be null if HMR triggers
                if (this.scene)
                    this.scene.cameras.main.pan(x * TILE_SIZE, y * TILE_SIZE, 100, "Linear", true)
            }
            else {
                sprite.visible = false
            }
        })


        /**  */
        var mouseHighlight = scene.add.rectangle(0, 0, TILE_SIZE, TILE_SIZE, 0xffffff);
        mouseHighlight.alpha = 0.2;
        mouseHighlight.fillColor = 0xffffff;
        // mouseHighlight.fillRect(0, 0, TILE_SIZE, TILE_SIZE);
        mouseHighlight.setStrokeStyle(2, 0x00000);
        layer.setInteractive()
        layer.on(
            "pointermove",
            function (
                pointer: PointerEvent,
                localX: number,
                localY: number,
                gameObjects: any
            ) {
                if (scene.isDragging)
                    return
                mouseHighlight.setPosition(
                    parseInt("" + localX / TILE_SIZE, 10) * TILE_SIZE + TILE_SIZE / 2,
                    parseInt("" + localY / TILE_SIZE, 10) * TILE_SIZE + TILE_SIZE / 2
                )
            }
        )

        /** On click, select a tile */
        layer.on('pointerup', (
            pointer: PointerEvent,
            localX: number,
            localY: number,
            gameObjects: any
        ) => {


            let dragSpeed = 0
            let dragDuration = 0
            if (scene.dragStart && (Date.now() - scene.dragStart) > 0) {
                dragDuration = Date.now() - scene.dragStart
                dragSpeed = scene.dragMove / dragDuration
            }
            const smallDnD = dragSpeed < 1 && dragDuration < 100

            // if (!scene.isDragging && smallDnD)
            //     return
            // only left click
            if (pointer.button !== 0)
                return
            // because canvas takes event before dom element that is behind him
            // @ts-ignore
            if (pointer?.event?.currentTarget?.tagName?.toLowerCase() !== "canvas")
                return

            if (smallDnD || !scene.isDragging) {
                // recomputes mouse coordinatse in case the phaser canvas have been moved on the page
                this.scene.scale.updateBounds()
                var worldPoint = scene.input.activePointer.positionToCamera(
                    scene.cameras.main,
                    Phaser.Math.Vector2
                ) as Phaser.Math.Vector2
                // console.log(worldPoint)

                // Rounds down to nearest tile
                var pointerTileX = scene.map.worldToTileX(worldPoint.x)
                var pointerTileY = scene.map.worldToTileY(worldPoint.y)

                // Snap to tile coordinates, but in world space
                // marker.x = map.tileToWorldX(pointerTileX)
                // marker.y = map.tileToWorldY(pointerTileY)
                // console.log(pointerTileX)
                // console.log(pointerTileY)
                if (pointerTileX !== null && pointerTileY !== null) {
                    const selectedTile = scene.map.getTileAt(pointerTileX, pointerTileY, true, "background")

                    if (selectedTile) {
                        this.mapStore.setSelectedTile(selectedTile.x, selectedTile.y)
                    }
                }
                // else
                //     this.mapStore.unselectTile()
            }

        }
        )


    }

    update(): void {

    }
}

